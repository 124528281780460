<template>
  <section>
    <v-row class="mb-5 mr-0 ml-0">
      <v-tabs color="primary darken-1" v-model="tab" centered icons-and-text show-arrows grow>
        <v-tab class="flex-grow-1 pl-3 pr-3" @click="$router.push({name: 'profileInfo'}, () => {})" active-class="primary lighten-1 white--text px-2">{{ $t('information') }}
          <v-icon class="mb-2">mdi-account-outline</v-icon>
        </v-tab>
        <v-tab class="flex-grow-1 pl-3 pr-3" @click="$router.push({name: 'profilePlans'}, () => {})" active-class="primary lighten-1 white--text px-2">{{ $t('plan_subscription') }}
          <v-icon class="mb-2">mdi-account-cash-outline</v-icon>
        </v-tab>
        <v-tab class="flex-grow-1 pl-3 pr-3" @click="$router.push({name: 'profilePassword'}, () => {})" active-class="primary lighten-1 white--text px-2">{{ $t('safety') }}
          <v-icon class="mb-2">mdi-form-textbox-password</v-icon>
        </v-tab>
        <v-tab class="flex-grow-1 pl-3 pr-3" @click="$router.push({name: 'profileNotifications'}, () => {})" active-class="primary lighten-1 white--text px-2">{{ $t('notifications') }}
          <v-icon class="mb-2">mdi-bell-outline</v-icon>
        </v-tab>
      </v-tabs>
    </v-row>
  </section>
</template>

<script>
export default {
  data() {
    return {
      tab: this.$route.meta.index
    }
  }
}
</script>
